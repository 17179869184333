<template>
    <a-modal title="选择物料" v-model:visible="visible" :width="950" @ok="finish" destroyOnClose>
        <div style="width:100%;display:flex;flex-wrap: wrap">
            <div style="display:flex;flex-grow:2;align-items:center;width:180px">
                <span style="margin-left:0px;flex-shrink:0">物料名称：</span>
                <a-input v-model:value="material_name" style="flex-grow:1"></a-input>
            </div>
            <div style="display:flex;align-items:center;">
                <span style="margin-left:12px;flex-shrink:0">物料编号：</span>
                <a-input-group compact>
                    <a-input v-model:value="prefix" style="width:40px;text-align:center" :maxlength="2" />
                    <a-input style="width:20px;border-left:0;padding:4px 0;text-align:center" placeholder="-" disabled />
                    <a-input v-model:value="suffix" style="width:60px; text-align:center;border-left:0" :maxlength="4" />
                </a-input-group>
            </div>
            <div style="display:flex;flex-grow:3;align-items:center;">
                <span style="margin-left:12px;flex-shrink:0">分类：</span>
                <a-tree-select style="flex-grow:1" v-model:value="category_id" :tree-data="category_tree" :replaceFields="{ children: 'children', title: 'name', key: 'id', value: 'id' }" treeDefaultExpandAll />
            </div>
            <div style="display:flex;align-items:center;">
                <span style="margin-left:12px;flex-shrink:0">标签：</span>
                <a-input-group compact>
                    <a-select v-model:value="tags_mode">
                        <a-select-option value="or">或含</a-select-option>
                        <a-select-option value="and">并含</a-select-option>
                    </a-select>
                    <a-select :maxTagCount="1" v-model:value="tags" mode="multiple" :options="tags_options" style="min-width:120px" />
                </a-input-group>
            </div>
            <a-button style="margin-left:12px;" @click="search">检索</a-button>
        </div>
        <a-table :rowSelection="{ selectedRowKeys: select_material_id_list, onChange: select, type: multiple ? 'checkbox' : 'radio' }" style="margin:10px 0px;" :columns="table_columns" :data-source="table_data" rowKey="id" :pagination="false" bordered size="small"> </a-table>
        <a-pagination style="display:flex;justify-content:center;" show-size-changer v-model:current="page" v-model:pageSize="perpage" :total="data_count" @change="search" @showSizeChange="search" :pageSizeOptions="['10', '15', '20']" size="small" v-if="data_count" />
        <template #footer>
            <div style="display:flex;">
                <span style="text-align:left;flex-grow:1;line-height:32px">当前已选中 {{ select_material_id_list.length }} 种物料</span>
                <a-button @click="visible = false">关闭</a-button>
                <a-button @click="finish" type="primary">确定</a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
/**
 * 该组件使用方法
 * 1.在父组件中为该组件设置属性：ref='xxx'
 * 2.在父组件中使用this.$refs['xxx'].start()调用该子组件的start方法开始选择，start的调用方法见method
 * 3.监听finish事件，返回的参数为选择的id列表，多选时如：[1,2,3]，单选情况也为列表，如：[4]
 *
 * 支持id（物料id）与code（物料编码）两种格式的数据（传入、传出）
 */
import { DoubleRightOutlined, InfoCircleOutlined } from '@ant-design/icons-vue';
export default {
    components: {
        DoubleRightOutlined,
        InfoCircleOutlined,
    },
    name: 'SelectRoleModal',
    data() {
        return {
            visible: false,
            // 可供用于选择的物料列表
            could_select_material_list: [],
            // 已选择的物料id列表
            select_material_id_list: [],
            // 下面是用于检索物料的条件字段
            material_name: '',
            prefix: '',
            suffix: '',
            category_id: 0,
            tags_mode: 'or',
            tags: [],
            page: 1,
            perpage: 10,
            // 基础数据
            category_tree: [],
            tags_options: [],
            category_list: [],
            table_columns: [
                { title: '物料编码', dataIndex: 'material_code', align: 'center', width: 100 },
                { title: '名称', dataIndex: 'name' },
                { title: '所属分类', dataIndex: 'category_name' },
            ],

            table_data: [],
            data_count: 0,
            total_id_to_code: {},
        };
    },
    mounted() {
        let flag = [false, false, false];
        this.$api('getCategoryTree').then((res) => {
            this.category_tree = res.category_tree;
            flag[0] = true;
        });
        this.$api('getCategoryList', { parent_id: -1 }).then((res) => {
            this.category_list = res.category_list;
            flag[1] = true;
        });
        this.$api('getMaterialTagList').then((res) => {
            res.material_tag_list.forEach((item) => {
                this.tags_options.push({ label: item, value: item });
            });
            flag[2] = true;
        });
        let f = () => {
            if (flag[0] && flag[1] && flag[2]) {
                this.search();
            } else {
                setTimeout(f, 20);
            }
        };
        f();
    },
    props: {
        // 是否支持多选
        multiple: {
            type: Boolean,
            default: true,
        },
        // 是否允许空选
        allowNull: {
            type: Boolean,
            default: true,
        },
        // 采用的数据类型：id、code（编码）
        dataType: {
            type: String,
            default: 'id',
        },
    },
    watch: {},
    methods: {
        /**
         * @param {[String|Number]} select_material_list 当前已选中的物料列表，不传入时默认为空，根据dataType的不同，接受传入的数据格式也不同
         */
        async start(select_material_list = []) {
            // 用于判断元素是否为数字或字符串数字的函数，[true：0 1 '2' 3.4 '5.6']
            let f1 = (v) => {
                if (this.dataType == 'id') {
                    return typeof v === 'number' || (typeof v === 'string' && !isNaN(Number(v)));
                } else if (this.dataType == 'code') {
                    v = String(v).toUpperCase();
                    for (let i in v) {
                        if ('0123456789ABCEFGHJKLMNPQRSTUVWXY-'.indexOf(v[i]) == -1) return false;
                    }
                    v = v.split('-');
                    return v.length == 2 && v[0].length == 2 && v[1].length == 4;
                }
                console.error('dataType格式不正确');
                return false;
            };
            // 将符合f1条件的转为字符串整形数字，[1=>'1'] ['2.3'=>'2'] [4.5=>'4']
            let f2 = async (v) => {
                if (this.dataType == 'id') {
                    return parseInt(v);
                } else if (this.dataType == 'code') {
                    v = String(String(v).toUpperCase()).split('-');
                    let prefix = v[0];
                    let suffix = this._32to10(v[1]);
                    let res = await this.$api('getMaterialBrief', { prefix, suffix });
                    this.total_id_to_code[res.material_brief.material_id] = v[0] + '-' + v[1];
                    if (res.result) return res.material_brief.material_id;
                }
            };

            // 判断select_material_list是否合规
            if (!(select_material_list instanceof Array)) {
                return console.error('传入的select_material_list不是数组');
            }
            // // 转化select_material_list中的元素
            if (select_material_list.every(f1)) {
                select_material_list = await Promise.all(select_material_list.map(f2));
            } else {
                return console.error('传入的select_role_id_list列表中有不合规的元素');
            }

            // 单选模式下，如果已经选择大于1个物料时，仅取第一个
            if (!this.multiple && select_material_list.length > 1) {
                select_material_list = [select_material_list[0]];
            }

            this.visible = true;
            this.select_material_id_list = select_material_list.filter((v) => {
                return typeof v == 'number';
            });
        },
        _10to32(num10) {
            num10 = parseInt(num10);
            if (num10 < 0 || num10 > 1048575) {
                throw new Error('用于转换的10进制数太小或太大，仅限0~1048575，而你传入了：' + num10);
            }
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            let str32 = '';
            while (true) {
                str32 = map[num10 % 32] + str32;
                num10 = parseInt(num10 / 32);
                if (num10 == 0) {
                    return ('000' + str32).slice(-4);
                }
            }
        },
        _32to10(str32) {
            str32 = String(str32);
            if (str32.length > 4 || str32.length < 1) {
                throw new Error('用于转换的32进制数太小或太大，仅限0000~YYYY，而你传入了：' + str32);
            }
            let num10 = 0;
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            for (let i = 0; i < str32.length; i++) {
                let index = map.indexOf(str32[i]);
                if (index == -1) {
                    throw new Error('用于转换的32进制数字符不符，仅限0123456789ABCEFGHJKLMNPQRSTUVWXY，而你传入了：' + str32[i]);
                }
                num10 += index * 32 ** (str32.length - 1 - i);
            }
            return num10;
        },
        search() {
            let f = (v) => {
                return '0123456789ABCEFGHJKLMNPQRSTUVWXY'.indexOf(v) != -1;
            };
            let conditions = {
                flag: {
                    material_name: !!this.material_name.replace(' ', ''),
                    prefix: this.prefix.replace(' ', '').length == 2 && this.prefix.split('').every(f),
                    suffix: this.suffix.replace(' ', '').length == 4 && this.suffix.split('').every(f),
                    tags: !!this.tags.length,
                    admin: false,
                },
                material_name: this.material_name,
                prefix: this.prefix,
                suffix: this.suffix,
                category_id: this.category_id,
                tags_mode: this.tags_mode,
                tags: this.tags,
            };
            if (conditions.flag.suffix) conditions.suffix = this._32to10(conditions.suffix);
            conditions = JSON.stringify(conditions);
            this.$api('searchMaterial', { conditions, page: this.page, perpage: this.perpage }).then((res) => {
                this.data_count = res.count;
                this.table_data = res.material_list;
                for (let i = 0; i < this.table_data.length; i++) {
                    this.table_data[i].material_code = this.table_data[i].prefix + '-' + this._10to32(this.table_data[i].suffix);
                    this.category_list.forEach((item) => {
                        if (item.id == this.table_data[i].category_id) this.table_data[i].category_name = item.name;
                    });
                    this.total_id_to_code[this.table_data[i].id] = this.table_data[i].material_code;
                }
            });
        },
        finish() {
            let temp = [];
            this.select_material_id_list.forEach((item) => {
                if (this.dataType == 'id') temp.push(item);
                if (this.dataType == 'code') temp.push(this.total_id_to_code[item]);
            });
            this.$emit('finish', temp);
            this.visible = false;
        },
        select(value) {
            this.select_material_id_list = value;
        },
    },
};
</script>

<style scoped>
.list {
    min-height: 180px;
    max-height: 400px;
    overflow: auto;
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    align-self: stretch;
}
</style>
